<template>
  <!-- プロジェクトテンプレートリスト -->
  <div class="template-wrap">
    <transition-toggle-contents>
      <div
        v-if="(!waitSearch || !projectTemplateLoading) && !changeCategory"
        key="search"
        class="template-block"
      >
        <div class="template-title">
          <texts size="big" :text="categoryName" />
          <input-box
            class="template-input"
            :placeholder="$t('projectTemplate.search')"
            isSearch
            isGray
            @input="$emit('input-search', $event)"
            @change="fixSearchText($event)"
          />
        </div>
        <transition-group
          class="template-list"
          name="toggle-templates"
          tag="div"
        >
          <project-template-card
            v-for="item in projectTemplates"
            :id="item.id"
            :key="item.id"
            class="template-item"
            :name="item.name"
            :tags="item.tags"
            :disabledChecked="item.disabledChecked"
            :enabledEdit="enabledEdit"
            :isAuthor="item.isAuthor"
            :thumbnailUrl="item.thumbnailUrl"
            @check-template-card="$emit('check-template-card', $event)"
          />
          <project-support-card
            key="contact"
            class="template-item"
            @go-support-form="goSupportForm"
          />
        </transition-group>
      </div>

      <div v-else class="template-block">
        <div class="loading-wrap">
          <loading />
        </div>
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading.vue'
import inputBox from '@/components/molecules/input-box.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import projectTemplateCard from '@/components/organisms/select-template/project-template-card'
import projectSupportCard from '@/components/organisms/project-template/project-template-support-card'

export default {
  components: {
    loading,
    inputBox,
    transitionToggleContents,
    projectTemplateCard,
    projectSupportCard
  },
  data() {
    return {
      changeCategory: false
    }
  },
  props: {
    enabledEdit: {
      type: Boolean,
      default: false
    },
    projectTemplates: Object,
    projectTemplateLoading: Boolean,
    projectTemplateCategories: Object,
    selectedProjectCategoryName: String,
    searchWord: String,
    waitSearch: Boolean
  },
  computed: {
    categoryName() {
      if (!this.selectedProjectCategoryName)
        return this.$t('projectTemplate.category.typical')
      return this.selectedProjectCategoryName
    }
  },
  methods: {
    goSupportForm: function () {
      this.$emit('go-support-form')
    },
    fixSearchText: function (event) {
      this.$gtmDataLayer.sendEventCategory(
        'プロジェクトテンプレートの検索',
        '検索ボックスから検索',
        event
      )
    }
  },
  watch: {
    categoryName() {
      this.changeCategory = true
      this.$nextTick(() => {
        this.changeCategory = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: adjustVH(480);
}

.toggle-templates-enter,
.toggle-templates-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}

.template {
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: adjustVW(48 + 8);
    padding: 0 $space-medium $space-base;
    z-index: 2;
  }
  &-block {
    position: relative;
    width: 100%;
    margin-left: 0;
  }
  &-input {
    height: 100%;
    &::v-deep .c-func-input-inner-gray {
      background-color: #fff;
      box-shadow: 0 0 0 adjustVW(1) $border-gray inset;
      &:focus {
        box-shadow: $border-radius-emphasis inset, $box-shadow-hover;
      }
    }
  }
  &-list {
    position: relative;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    padding: $space-text $space-medium $space-medium;
  }
  &-item {
    position: relative;
    width: 100%;
    transition: all $transition-base;
  }
}
</style>
