<template>
  <card class="wrap" @click="linkToDetail({ id })">
    <div class="inner">
      <div class="image">
        <div
          v-if="enabledEdit && isAuthor"
          class="checkbox"
          @click.stop
          @mouseenter="showTips = true"
          @mouseleave="showTips = false"
        >
          <checkbox-base
            :checked="checked"
            :isDisabled="disabledChecked"
            @input="checkTemplateCard($event)"
          />
        </div>
        <image-lazy-load class="image-item" :src="imagePath()" />
      </div>
      <div class="content">
        <div class="main">
          <texts
            class="title"
            isShowAll
            :text="name"
            :size="18"
            isBold
          />
        </div>
        <div class="category">
          <dot-text
            v-for="(tag, index) in tags"
            :key="index"
            class="category-item"
            :text="tag"
            :size="13.5"
            :maxWidth="240"
            color="gray"
          />
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import card from '@/components/atoms/card'
import checkboxBase from '@/components/atoms/checkbox-base.vue'
import dotText from '@/components/atoms/dot-text'
import imageLazyLoad from '@/components/atoms/image-lazy-load'

export default {
  components: {
    card,
    checkboxBase,
    dotText,
    imageLazyLoad
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    name: String,
    tags: Array,
    checked: Boolean,
    disabledChecked: {
      type: Boolean,
      default: false
    },
    enabledEdit: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    thumbnailUrl: String
  },
  methods: {
    linkToDetail: function ({ id }) {
      this.$router.push({
        name: 'projectTemplateDetail',
        params: { id }
      })
    },
    checkTemplateCard: function (e) {
      this.$emit('check-template-card', {
        id: this.id,
        checked: e
      })
    },
    imagePath() {
      if (this.thumbnailUrl) return this.thumbnailUrl
      return require('@/assets/images/logo.png')
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: $space-small;
  border: $border-sub;
  border-radius: adjustVW(8);
  box-shadow: none;
  &:hover {
    background: #fff;
    box-shadow: $box-shadow-main;
    opacity: 0.8;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  &:hover {
    opacity: 1;
  }
}

.category {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: $space-sub;
  grid-row-gap: $space-sub;
  &-item {
    max-width: adjustVW(184);
  }
}

.checkbox {
  position: absolute;
  top: $space-base;
  right: $space-sub;
  flex-shrink: 0;
  width: adjustVW(24);
  z-index: 1;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border-bottom: $border-sub;
  border-radius: adjustVW(8) adjustVW(8) 0 0;
  &-item {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    filter: brightness(0.98);
    border-radius: adjustVW(8) adjustVW(8) 0 0;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: calc(56.25% + $space-small) 0 0;
}

.title {
  margin-bottom: $space-sub;
  line-height: 1.5;
  white-space: pre-line;
}
</style>
