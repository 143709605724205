<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :title="$t('common.trainLeave.popup.title')"
      :saveButton="
        $t('common.trainLeave.popup.saveButton', { currentPage: currentPage })
      "
      :leaveButton="$t('common.trainLeave.popup.leaveButtonProject')"
      leaveButtonColor="sub"
      @save-event="closePrevent"
    >
      <texts class="prevent-leave-text" isShowAll size="small">
        <span
          v-html="
            $t('common.trainLeave.reason.first', {
              leavePage: leavePage,
              currentPage: currentPage
            })
          "
        />
        <span
          v-html="
            $t('common.trainLeave.reason.second', { currentPage: currentPage })
          "
        />
        <span v-html="$t('common.trainLeave.reason.project')" />
      </texts>
    </prevent-leave>
    <project-template
      v-bind="data"
      :headerTabs="headerTabs"
      :sidebar="sidebar"
      :projectTemplates="showTemplates"
      :projectTemplateLoading="projectTemplateLoading"
      :projectTemplateCategories="projectTemplateCategories"
      :selectedProjectCategoryName="selectedProjectCategoryName"
      :searchWord="searchWord"
      :suggest="suggest"
      :showSuggest="showSuggest"
      :waitSearch="waitSearch"
      @check-template-card="checkTemplateCard($event)"
      @close-modal="closeModal($event)"
      @delete-project-template="deleteProjectTemplateSubmit($event)"
      @go-support-form="goSupportForm"
      @input-search="inputSearch($event)"
      @reset-search="resetSearch"
      @page-back="pageBack"
      @template-menu-click="templateMenuClick($event)"
      @click-category="clickProjectCategory($event)"
    />
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import { mapActions, mapGetters } from 'vuex'
import {
  checkOuterTraining,
  checkCorrectBackPage
} from '@/lib/training-pages-correct-behavior'
import { localProjectTemplateCategories } from '@/lib/projectTemplate'
import setMountedTimer from '@/mixin/set-mounted-timer'

import projectTemplate from '@/components/templates/project-template'
import preventLeave from '@/components/molecules/prevent-leave'

export default {
  components: {
    projectTemplate,
    preventLeave
  },
  mixins: [setMountedTimer],
  async beforeRouteLeave(to, from, next) {
    this.isPrevent = this.checkPrevent(to.name)

    if (this.isPrevent) {
      this.leavePageName = to.name
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        this.$router.push({
          name: 'projectList'
        })
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.projectTemplateLoading = true

      await vm.$waitConnected()

      if (!vm.projectList || Object.keys(vm.projectList).length === 0) {
        await vm.loadProjectList()
      }

      await vm.fectchTemplateUserInfo()

      vm.makeProjectTemplateList()
      vm.initFuseSerch()

      vm.projectTemplateLoading = false
    })
  },
  data() {
    return {
      data: {
        body: {
          projectTemplateList: {
            enabledEdit: false
          },
          top: {
            checkedTemplateList: [],
            isTemplateUser: false,
            isAuthor: false
          }
        },
        popup: {
          deleteProjectTemplate: {},
          showPopup: []
        }
      },
      projectTemplates: {},
      baseProjectTemplates: {},
      typicalProjectTemplates: {},
      projectTemplateLoading: false,
      showSuggest: false,
      popupSearch: false,
      searchWord: '',
      searchItems: [],
      showTemplates: {},
      suggest: {
        /** サジェストが入ります **/ goal: [],
        target: [],
        industry: []
      },
      waitSearch: false /** 検索結果を待っている間のローディングを表示するかどうか */,
      fuse: null,
      isPrevent: false,
      correctTransition: false,
      currentPageName: this.$route.name,
      leavePageName: null,
      selectedProjectCategoryName: null
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('projectTemplate', ['projectList']),

    headerTabs() {
      return {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      }
    },
    sidebar() {
      return {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります',
          template: '退職予測テンプレート',
          dataset: 'データセット名が入ります',
          target: '予測する列名が入ります',
          preprocessingDataset: '前処理後のデータセットが入ります',
          recipe: '使用するレシピ名が入ります'
        },
        activeLink: 'projectTemplate',
        status: 'select'
      }
    },
    leavePage() {
      return this.$t('common.trainLeave.titles.' + this.leavePageName)
    },
    currentPage() {
      return this.$t('common.trainLeave.titles.' + this.currentPageName)
    },
    projectTemplateCategories() {
      if (!this.projectList || Object.keys(this.projectList).length === 0)
        return {}
      const categories = JSON.parse(
        JSON.stringify(localProjectTemplateCategories)
      )
      const res = Object.entries(categories).reduce((acc, [key, value]) => {
        const fixValue = value.map((item) => {
          // TODO 英語表記のカテゴリー設定ができるまで日本語で検索
          const ja = this.$t(
            'projectTemplate.category.' + key + '.' + item.category,
            'ja'
          )
          const check = Object.values(this.projectList).some((template) => {
            return template.tags.includes(ja)
          })
          item.show = check
          return { ...item }
        })
        return { ...acc, [key]: fixValue }
      }, {})
      return res
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    ...mapActions('projectTemplate', ['loadProjectList', 'deleteProjectTemplate']),

    goSupportForm: function () {
      const url = this.$urls.contactLink
      window.open(url, '_blank')
    },
    inputSearch($event) {
      // inputされた値がeventに入っています
      if ($event) {
        // ダミーデータを代入しています。
        // サジェストに出したい内容は
        // * AIで実現したいこと
        // * AIが予測分類する対象
        // *業界職種
        const results = this.fuse.search($event)
        this.showTemplates = results.reduce((list, result) => {
          const id = result.item.id

          list[id] = this.projectTemplates[id]
          return list
        }, {})

        // this.suggest = Object.assign({}, this.suggest, this.dummySuggest)
        // this.showSuggest = true // サジェストのポップアップを表示
      } else {
        this.showTemplates = this.projectTemplates
        // this.showSuggest = false
      }
    },
    async resetSearch() {
      // 検索結果を閉じて、再度テンプレート一覧を表示する
      this.searchWord = ''
      await this.loadProjectTemplateList()
    },

    makeProjectTemplateList() {
      const templateObject = this.projectList
      const keys = Object.keys(templateObject)

      this.baseProjectTemplates = keys.reduce((result, key) => {
        const project = templateObject[key]
        const author = project.author.account_id

        result[key] = {
          author: project.author,
          id: Number(key),
          name: project.name,
          tags: project.tags,
          checked: false,
          disabledChecked: false,
          isAuthor: author === this.accountInfo.accountId,
          thumbnailUrl: project?.thumbnailUrl
        }
        return result
      }, {})
      this.setTypicalProjectTemplates()
      this.projectTemplates = JSON.parse(
        JSON.stringify(this.typicalProjectTemplates)
      )
    },
    setTypicalProjectTemplates() {
      if (
        !this.baseProjectTemplates ||
        Object.keys(this.baseProjectTemplates).length === 0
      )
        return {}
      const target = JSON.parse(JSON.stringify(this.baseProjectTemplates))
      const res = Object.entries(target).reduce((acc, [key, project]) => {
        const check = project.tags.some((tag) => {
          return tag === this.$t('projectTemplate.category.typical', 'ja')
        })
        if (check) {
          acc[key] = project
        }
        return acc
      }, {})
      // 代表的な事例が０の場合は、baseProjectTemplatesをそのまま出す
      if (Object.keys(res).length === 0) {
        this.typicalProjectTemplates = target
      } else {
        this.typicalProjectTemplates = res
      }
    },
    initFuseSerch: function () {
      this.showTemplates = this.typicalProjectTemplates
      const templatesObject = this.typicalProjectTemplates
      this.setFuse(templatesObject)
    },
    setFuse(objects) {
      const keys = Object.keys(objects)
      this.searchItems = keys.map((key) => objects[key])
      const fuseOptions = {
        includeScore: true,
        shouldSort: true,
        keys: ['name', 'goal', 'target', 'tags']
      }
      this.fuse = new Fuse(this.searchItems, fuseOptions)
    },
    checkPrevent(nextPageName) {
      return (
        !checkOuterTraining(nextPageName) &&
        !checkCorrectBackPage('project', nextPageName) &&
        !this.correctTransition
      )
    },
    checkTemplateCard: function ({ id, checked }) {
      const keys = Object.keys(this.projectTemplates)

      keys.forEach((key) => {
        if (this.projectTemplates[key].id === id) {
          this.$set(this.projectTemplates[key], 'checked', checked)
        } else {
          this.$set(this.projectTemplates[key], 'disabledChecked', checked)
        }
      })

      if (checked) {
        this.$set(this.data.body.top, 'checkedTemplateList', [id])
      } else {
        this.$set(this.data.body.top, 'checkedTemplateList', [])
      }
    },
    closeModal: function (popupName) {
      this.data.popup.showPopup = this.data.popup.showPopup.filter(
        (name) => name !== popupName
      )
    },
    closePrevent() {
      this.$refs.preventLeave.closePopup()
    },
    deleteProjectTemplateSubmit: async function ({ templateId }) {
      try {
        await this.deleteProjectTemplate({ templateId })
      } catch (err) {
        this.log_info(err)
      } finally {
        this.closeModal('deleteProjectTemplate')

        this.projectTemplateLoading = true

        await this.loadProjectList()
        await this.fectchTemplateUserInfo()
        this.makeProjectTemplateList()
        this.initFuseSerch()

        this.projectTemplateLoading = false
      }
    },
    fectchTemplateUserInfo: async function () {
      await this.fetchAccountInfo()

      const accountOptions = this.accountInfo.planDetail.options
      const templateUser = accountOptions.find(
        (item) => item.name === 'project_template_user'
      )

      this.data.body.projectTemplateList.enabledEdit =
        templateUser !== undefined
      this.data.body.top.isTemplateUser = templateUser !== undefined
    },
    pageBack() {
      this.$router.push({
        name: 'selectMode'
      })
    },
    templateMenuClick: function ({ activeMenu, templateId }) {
      if (activeMenu === 'edit') {
        this.$router.push({
          name: 'projectTemplateEdit',
          params: {
            projectTemplateId: templateId
          }
        })
      } else if (activeMenu === 'delete') {
        const keys = Object.keys(this.projectTemplates)

        const target = keys.reduce((result, key) => {
          const project = this.projectTemplates[key]

          if (project.id === templateId) {
            result = {
              id: key,
              name: project.name,
              goal: project.wantToAchieve,
              target: project.predictedAndClassified,
              description: project.description,
              tags: project.tags,
              checked: false,
              disabledChecked: false
            }
          }
          return result
        }, {})

        this.$set(this.data.popup, 'deleteProjectTemplate', target)

        this.data.popup.showPopup.push('deleteProjectTemplate')
      }
    },
    clickProjectCategory({ type, category, name }) {
      let results = {}
      let categoryName = name
      if (category) {
        const filteredTemplates = Object.values(
          this.baseProjectTemplates
        ).filter((item) => {
          return item.tags.some((tag) => {
            // TODO 英語表記のカテゴリー設定ができるまで日本語で検索
            const ja = this.$t(
              'projectTemplate.category.' + type + '.' + category,
              'ja'
            )
            return tag === ja
          })
        })
        results = filteredTemplates.reduce((list, template) => {
          const id = template.id
          list[id] = template
          return list
        }, {})
      } else {
        if (type === 'typical') {
          results = this.typicalProjectTemplates
        } else if (type === 'all') {
          results = this.baseProjectTemplates
        }
        categoryName = this.$t('projectTemplate.category.' + type)
      }
      this.selectedProjectCategoryName = categoryName
      this.projectTemplates = JSON.parse(JSON.stringify(results))
      this.showTemplates = JSON.parse(JSON.stringify(this.projectTemplates))
      this.setFuse(this.projectTemplates)
    }
  }
}
</script>

<style lang="scss" scoped>
.prevent-leave-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  ::v-deep {
    .prevent-leave-text-emphasis {
      color: $text-decoration;
    }
    .prevent-leave-text-caution {
      color: $text-caution;
    }
  }
}
</style>
