<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deleteProjectTemplate>
      <delete-template
        v-bind="deleteProjectTemplate"
        @close-modal="$emit('close-modal', 'deleteProjectTemplate')"
        @delete-project-template="$emit('delete-project-template', $event)"
      />
    </template>
  </popup-array>
</template>

<script>
import { popupArray } from '@/components/molecules/popup'
import DeleteTemplate from './popup/delete-template.vue'

export default {
  components: {
    DeleteTemplate,
    popupArray
  },
  computed: {
    popupDefs() {
      return {
        deleteProjectTemplate: {
          title: this.$t('common.deleteConfirm')
        }
      }
    }
  },
  props: {
    deleteProjectTemplate: Object,
    showPopup: Array
  }
}
</script>
