<template>
  <div class="c-wrap">
    <project-template-sidebar
      :projectTemplateCategories="projectTemplateCategories"
      :selectedProjectCategoryName="selectedProjectCategoryName"
      :projectTemplateLoading="projectTemplateLoading"
      @click-category="$emit('click-category', $event)"
    />

    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="projectTemplateLoading" />
          <project-template
            v-else
            v-bind="body"
            :projectTemplates="projectTemplates"
            :projectTemplateLoading="projectTemplateLoading"
            :projectTemplateCategories="projectTemplateCategories"
            :selectedProjectCategoryName="selectedProjectCategoryName"
            :suggest="suggest"
            :showSuggest="showSuggest"
            :searchWord="searchWord"
            :waitSearch="waitSearch"
            @check-template-card="$emit('check-template-card', $event)"
            @close-suggest="$emit('close-suggest')"
            @go-support-form="goSupportForm"
            @input-search="$emit('input-search', $event)"
            @page-back="$emit('page-back')"
            @reset-search="$emit('reset-search')"
            @set-search-target="$emit('set-search-target', $event)"
            @template-menu-click="$emit('template-menu-click', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>

    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <project-template-list-popups
      v-bind="popup"
      @close-modal="$emit('close-modal', $event)"
      @delete-project-template="$emit('delete-project-template', $event)"
    />
  </div>
</template>

<script>
import projectTemplate from '@/components/organisms/project-template/project-template.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import Loading from '@/components/atoms/loading.vue'
import ProjectTemplateListPopups from '@/components/organisms/project-template/project-template-list-popups'
import projectTemplateSidebar from '@/components/organisms/project-template/project-template-sidebar'

export default {
  components: {
    projectTemplate,
    headerTabs,
    hoverMenu,
    transitionPage,
    TransitionToggleContents,
    Loading,
    ProjectTemplateListPopups,
    projectTemplateSidebar
  },
  props: {
    body: Object,
    sidebar: Object,
    headerTabs: Object,
    projectTemplates: Object,
    projectTemplateLoading: Boolean,
    projectTemplateCategories: Object,
    selectedProjectCategoryName: String,
    suggest: Object,
    showSuggest: Boolean,
    popup: Object,
    searchWord: String,
    waitSearch: Boolean
  },
  methods: {
    goSupportForm: function () {
      this.$emit('go-support-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
