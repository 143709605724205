<template>
  <!--
    このページは検索後に現れるpopupです
  -->
  <card-base class="wrap-popup">
    <!--
      Alで実現したいこと(カードの左側)
    -->
    <div v-if="goal.length" class="wrap-popup-list">
      <text-with-icon
        :text="$t('projectTemplate.select.popup.wantToAchieve')"
        iconName="selectGoal"
        class="text-title"
      />
      <button
        v-for="(item, index) in goal"
        :key="index + 'goal'"
        class="text-wrap"
        @click="$emit('set-search-target', item.name)"
      >
        <texts class="text-name" :text="item.name" />
        <text-with-icon
          class="text-template"
          :text="item.template"
          color="gray"
          iconName="connect"
        />
      </button>
    </div>
    <!--
      Alが予測・分岐する対象(カードの中央)
    -->
    <div v-if="target.length" class="wrap-popup-list">
      <text-with-icon
        :text="$t('projectTemplate.select.popup.predictedAndClassified')"
        iconName="selectTarget"
        class="text-title"
      />
      <button
        v-for="(item, index) in target"
        :key="index + 'target'"
        class="text-wrap"
        @click="$emit('set-search-target', item.name)"
      >
        <texts class="text-name" :text="item.name" />
        <text-with-icon
          class="text-template"
          :text="item.template"
          color="gray"
          iconName="connect"
        />
      </button>
    </div>
    <!--
      業界・職種(カードの右側)
    -->
    <div v-if="industry.length" class="wrap-popup-list">
      <text-with-icon
        :text="$t('projectTemplate.select.popup.industryOccupation')"
        iconName="selectIndustry"
        class="text-title"
      />
      <button
        v-for="(item, index) in industry"
        :key="index + 'industry'"
        class="text-wrap"
        @click="$emit('set-search-target', item.name)"
      >
        <texts class="text-only" :text="item.name" />
      </button>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import texts from '@/components/atoms/text'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    cardBase,
    texts,
    textWithIcon
  },
  props: {
    goal: Array,
    target: Array,
    industry: Array
  }
}
</script>

<style lang="scss" scoped>
.wrap-popup {
  display: flex;
  &-list {
    width: adjustVW(376);
    margin: 0 $space-small 0 0;
    &:last-of-type {
      margin: 0;
    }
  }
}
.text {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: adjustVH(80);
    padding: $space-base;
    border-bottom: $border-main;
    text-align: left;
    transition: background-color $transition-base;
    &:last-of-type {
      margin: 0;
      border: none;
    }
    &:hover {
      background: $background-decoration;
      opacity: 1;
    }
  }
  &-name {
    width: 100%;
    margin: 0 0 $space-text;
  }
  &-only {
    width: 100%;
    margin: 0;
  }
  &-template {
    width: 100%;
  }
  &-title {
    margin: 0 0 $space-sub;
  }
}
</style>
