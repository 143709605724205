<template>
  <div class="project-template-wrap">
    <project-template-top
      v-bind="top"
      class="project-template-top"
      :suggest="suggest"
      :showSuggest="showSuggest"
      @close-suggest="$emit('close-suggest')"
      @page-back="$emit('page-back')"
      @template-menu-click="$emit('template-menu-click', $event)"
    />
    <project-template-list
      v-bind="projectTemplateList"
      :projectTemplates="projectTemplates"
      :projectTemplateLoading="projectTemplateLoading"
      :projectTemplateCategories="projectTemplateCategories"
      :selectedProjectCategoryName="selectedProjectCategoryName"
      :searchWord="searchWord"
      :waitSearch="waitSearch"
      @check-template-card="$emit('check-template-card', $event)"
      @go-support-form="goSupportForm"
      @reset-search="$emit('reset-search')"
      @input-search="$emit('input-search', $event)"
    />
  </div>
</template>

<script>
import projectTemplateTop from '@/components/organisms/project-template/project-template-top.vue'
import projectTemplateList from '@/components/organisms/project-template/project-template-list.vue'

export default {
  components: {
    projectTemplateTop,
    projectTemplateList
  },
  props: {
    projectTemplates: Object,
    projectTemplateList: Object,
    projectTemplateLoading: Boolean,
    projectTemplateCategories: Object,
    selectedProjectCategoryName: String,
    suggest: Object,
    showSuggest: Boolean,
    searchWord: String,
    top: Object,
    waitSearch: Boolean
  },
  methods: {
    goSupportForm: function () {
      this.$emit('go-support-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.project-template-wrap {
  padding: 0;
}
.project-template-top {
  height: adjustVH(180);
  padding: $space-medium $space-medium $space-small;
  margin: 0;
  box-shadow: none;
}
</style>
