<template>
  <card-base class="item-wrap">
    <div>
      <images class="item-image" imageName="askSupport" />
      <div class="item-description">
        <texts
          class="item-description-inner"
          :text="$t('projectTemplate.select.description')"
          isShowAll
          size="15"
          color="gray"
        />
      </div>
    </div>
    <div class="item-button">
      <button-main
        class="item-button-inner"
        :text="$t('projectTemplate.select.support')"
        size="small"
        @click="goSupportForm"
      />
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'
import images from '@/components/atoms/images.vue'
import buttonMain from '@/components/atoms/button-main.vue'

export default {
  components: {
    cardBase,
    texts,
    images,
    buttonMain
  },
  methods: {
    goSupportForm: function () {
      this.$emit('go-support-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border: $border-sub;
    border-radius: adjustVW(8);
    box-shadow: none;
  }
  &-image {
    width: adjustVW(168);
    height: auto;
    margin-bottom: $space-medium;
  }
  &-description {
    margin-bottom: $space-small;
    &-inner {
      white-space: pre-line;
    }
  }
  &-button {
    width: 100%;
    &-inner {
      width: 100%;
    }
  }
}
</style>
