<template>
  <div class="category">
    <div class="category-link">
      <router-link :to="{ name: 'projectList' }">
        <text-with-icon
          :text="$t('sidebar.train.backward')"
          iconName="backward"
          size="small"
        />
      </router-link>
    </div>
    <template v-if="!projectTemplateLoading">
      <div class="category-scroll">
        <div class="category-block">
          <button
            class="category-item"
            @click="clickCategory('typical', null, null)"
          >
            <texts
              :text="$t('projectTemplate.category.typical')"
              isBold
              size="small"
            />
          </button>
          <button
            class="category-item"
            @click="clickCategory('all', null, null)"
          >
            <texts
              :text="$t('projectTemplate.category.all')"
              isBold
              size="small"
            />
          </button>
        </div>
        <template v-for="(cat, key) in projectTemplateCategories">
          <!--
            
          -->
          <div v-if="checkExistCategory(cat)" :key="key" class="category-block">
            <toggle-contents
              isBold
              :title="$t('projectTemplate.detail.' + key)"
              isOpenStart
              size="small"
            >
              <template v-for="(item, itemIndex) in cat">
                <button
                  v-if="item.show"
                  :key="itemIndex"
                  class="category-item"
                  @click="clickCategory(key, item.category, item.name)"
                >
                  <texts
                    :text="item.name"
                    :color="
                      item.name === selectedProjectCategoryName
                        ? 'emphasis'
                        : 'gray'
                    "
                    size="15"
                  />
                </button>
              </template>
            </toggle-contents>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon'
import toggleContents from '@/components/molecules/toggle-contents'
export default {
  components: {
    textWithIcon,
    toggleContents
  },
  props: {
    projectTemplateCategories: Object,
    selectedProjectCategoryName: String,
    projectTemplateLoading: Boolean
  },
  methods: {
    checkExistCategory(categories) {
      return categories.some((category) => {
        return category?.show
      })
    },
    clickCategory(type, category, name) {
      if (name && name === this.selectedProjectCategoryName) return
      if (name) {
        this.$gtmDataLayer.sendEventCategory(
          'プロジェクトテンプレートの検索',
          'カテゴリーをクリック',
          name
        )
      } else {
        this.$gtmDataLayer.sendEventCategory(
          'プロジェクトテンプレートの検索',
          'カテゴリーをクリック',
          this.$t('projectTemplate.category.' + type)
        )
      }
      this.$emit('click-category', { type, category, name })
    }
  }
}
</script>
<style lang="scss" scoped>
.category {
  position: sticky;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: adjustVW(280);
  height: 100vh;
  padding: 0 $space-base $space-medium $space-small;
  background: $background;
  z-index: 1000;
  &-link {
    display: flex;
    align-items: center;
    height: adjustVH(80);
  }
  &-scroll {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-small;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-block {
    padding-bottom: $space-small;
    border-bottom: $border-sub;
    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &-item {
    overflow: hidden;
    display: block;
    width: 100%;
    margin-bottom: adjustVW(10);
    text-align: left;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
