<template>
  <div class="delete-template-wrap">
    <div class="delete-template-item">
      <texts size="large" :text="$t('projectTemplate.popup.name')" />
      <texts size="large" :text="name" />
    </div>

    <div class="submit-button">
      <button-main
        :text="$t('common.button.cancel')"
        @click="$emit('close-modal')"
      />
      <button-main
        button-type="submit"
        type="caution"
        :text="$t('common.deleteButton')"
        @click="deleteProjectTemplate"
      />
    </div>
  </div>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    ButtonMain,

    Texts
  },
  props: {
    id: String,
    name: String
  },
  methods: {
    deleteProjectTemplate() {
      this.$emit('delete-project-template', {
        templateId: this.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.delete-template-wrap {
  width: 100%;
  height: 100%;
}

.delete-template-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $space-medium;
}

.submit-button {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
</style>
