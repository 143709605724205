var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('prevent-leave',{ref:"preventLeave",attrs:{"title":_vm.$t('common.trainLeave.popup.title'),"saveButton":_vm.$t('common.trainLeave.popup.saveButton', { currentPage: _vm.currentPage }),"leaveButton":_vm.$t('common.trainLeave.popup.leaveButtonProject'),"leaveButtonColor":"sub"},on:{"save-event":_vm.closePrevent},model:{value:(_vm.isPrevent),callback:function ($$v) {_vm.isPrevent=$$v},expression:"isPrevent"}},[_c('texts',{staticClass:"prevent-leave-text",attrs:{"isShowAll":"","size":"small"}},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.first', {
            leavePage: _vm.leavePage,
            currentPage: _vm.currentPage
          })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.second', { currentPage: _vm.currentPage })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('common.trainLeave.reason.project'))}})])],1),_c('project-template',_vm._b({attrs:{"headerTabs":_vm.headerTabs,"sidebar":_vm.sidebar,"projectTemplates":_vm.showTemplates,"projectTemplateLoading":_vm.projectTemplateLoading,"projectTemplateCategories":_vm.projectTemplateCategories,"selectedProjectCategoryName":_vm.selectedProjectCategoryName,"searchWord":_vm.searchWord,"suggest":_vm.suggest,"showSuggest":_vm.showSuggest,"waitSearch":_vm.waitSearch},on:{"check-template-card":function($event){return _vm.checkTemplateCard($event)},"close-modal":function($event){return _vm.closeModal($event)},"delete-project-template":function($event){return _vm.deleteProjectTemplateSubmit($event)},"go-support-form":_vm.goSupportForm,"input-search":function($event){return _vm.inputSearch($event)},"reset-search":_vm.resetSearch,"page-back":_vm.pageBack,"template-menu-click":function($event){return _vm.templateMenuClick($event)},"click-category":function($event){return _vm.clickProjectCategory($event)}}},'project-template',_vm.data,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }