<template>
  <page-top
    :title="$t('projectTemplate.select.title')"
    iconName="backward"
    isButton
    @click="$emit('page-back')"
  >
    <texts
      class="top-desc"
      :text="$t('projectTemplate.description')"
      color="gray"
      size="small"
    />
    <div ref="inputArea" class="input-box">
      <div class="input-box-navi">
        <menu-list
          :checked="checkedTemplateList"
          :menus="menus"
          :isMenuShow="true"
          @menu-click="templateMenuClick($event)"
        />
      </div>

      <!--
        検索結果を表示するpopupです
      -->
      <transition-toggle-contents>
        <div v-if="showSuggest" class="search-result-warp">
          <search-list v-bind="suggest" />
        </div>
      </transition-toggle-contents>
    </div>
  </page-top>
</template>

<script>
import menuList from '@/components/molecules/menu-list'
import pageTop from '@/components/atoms/page-top'
import searchList from '@/components/organisms/project-template/project-template-search-list'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    menuList,
    pageTop,
    searchList,
    transitionToggleContents
  },
  data() {
    return {
      result: ''
    }
  },
  props: {
    checkedTemplateList: Array,
    isTemplateUser: {
      type: Boolean,
      default: false
    },
    suggest: Object,
    showSuggest: Boolean
  },
  computed: {
    menus() {
      const menuList = []
      if (this.isTemplateUser && this.checkedTemplateList.length > 0) {
        const projectDelete = {
          id: 1,
          name: this.$t('common.deleteButton'),
          iconName: 'delete',
          value: 'delete',
          isMultiple: true, // 複数選択可能かどうか
          disabled: false,
          tips: {
            name: this.$t('common.deleteButton')
          }
        }
        menuList.push(projectDelete)

        const editTemplate = {
          id: 2,
          iconName: 'edit',
          value: 'edit',
          isMultiple: false, // 複数選択可能かどうか
          tips: {
            name: this.$t('common.editButton')
          }
        }
        menuList.push(editTemplate)
      }
      return menuList
    }
  },
  methods: {
    templateMenuClick: function ({ activeMenu }) {
      this.$emit('template-menu-click', {
        activeMenu,
        templateId: this.checkedTemplateList[0]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-desc {
  margin-top: -$space-sub;
  white-space: pre-line;
}

.input-box-navi {
  position: absolute;
  top: $space-medium;
  right: adjustVW(88);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.search-result-warp {
  position: absolute;
  top: 100%;
  margin: $space-small 0 0 0;
}
</style>
