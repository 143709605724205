import { i18n } from '@/main'

export const localProjectTemplateCategories = {
  wantToAchieve: [
    {
      name: i18n.t('projectTemplate.category.wantToAchieve.demand-forecast'),
      category: 'demand-forecast'
    },
    {
      name: i18n.t('projectTemplate.category.wantToAchieve.anomaly-detection'),
      category: 'anomaly-detection'
    },
    {
      name: i18n.t(
        'projectTemplate.category.wantToAchieve.recruitment-forecast'
      ),
      category: 'recruitment-forecast'
    },
    {
      name: i18n.t(
        'projectTemplate.category.wantToAchieve.business-efficiency'
      ),
      category: 'business-efficiency'
    },
    {
      name: i18n.t('projectTemplate.category.wantToAchieve.cost-reduction'),
      category: 'cost-reduction'
    },
    {
      name: i18n.t('projectTemplate.category.wantToAchieve.food-loss'),
      category: 'food-loss'
    },
    {
      name: i18n.t(
        'projectTemplate.category.wantToAchieve.profit-maximization'
      ),
      category: 'profit-maximization'
    },
    {
      name: i18n.t('projectTemplate.category.wantToAchieve.new-service'),
      category: 'new-service'
    }
  ],
  industryOccupation: [
    {
      name: i18n.t('projectTemplate.category.industryOccupation.food'),
      category: 'food'
    },
    {
      name: i18n.t('projectTemplate.category.industryOccupation.manufacturing'),
      category: 'manufacturing'
    },
    {
      name: i18n.t('projectTemplate.category.industryOccupation.construction'),
      category: 'construction'
    },
    {
      name: i18n.t('projectTemplate.category.industryOccupation.finance'),
      category: 'finance'
    },
    {
      name: i18n.t('projectTemplate.category.industryOccupation.logistics'),
      category: 'logistics'
    },
    {
      name: i18n.t('projectTemplate.category.industryOccupation.medical'),
      category: 'medical'
    },
    {
      name: i18n.t('projectTemplate.category.industryOccupation.it'),
      category: 'it'
    },
    {
      name: i18n.t('projectTemplate.category.industryOccupation.others'),
      category: 'others'
    },
    {
      name: i18n.t('projectTemplate.category.industryOccupation.all-industry'),
      category: 'all-industry'
    }
  ],
  technology: [
    {
      name: i18n.t('projectTemplate.category.technology.timeseries'),
      category: 'timeseries'
    },
    {
      name: i18n.t(
        'projectTemplate.category.technology.category-classification'
      ),
      category: 'category-classification'
    },
    {
      name: i18n.t('projectTemplate.category.technology.numerical-forecast'),
      category: 'numerical-forecast'
    },
    {
      name: i18n.t('projectTemplate.category.technology.image-recognition'),
      category: 'image-recognition'
    },
    {
      name: i18n.t('projectTemplate.category.technology.text-mining'),
      category: 'text-mining'
    },
    {
      name: i18n.t('projectTemplate.category.technology.optimisation'),
      category: 'optimisation'
    }
  ]
}
